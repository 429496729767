import CheckIcon from '@mui/icons-material/Check'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import LogoutIcon from '@mui/icons-material/Logout'
import {
  Avatar,
  Box,
  Button,
  IconButton,
  MenuList,
  Paper,
  Popper,
  Typography,
} from '@mui/material'
import { SupportedNetworks } from '@solidant/spool-v2-fe-lib'
import { useWeb3React } from '@web3-react/core'
import { GnosisSafe } from '@web3-react/gnosis-safe'
import { ethers } from 'ethers'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import useConnectionState from '@/hooks/context/useConnectionState'
import useLocale from '@/hooks/context/useLocale'
import useTopMenuState from '@/hooks/context/useTopMenuState'
import useBreakpointDetection from '@/hooks/general/useBreakpointDetection'

import { mapIcons } from '@/components/atoms/CryptoIcons'

import { IS_PUBLIC_TESTNET } from '@/config/sdk'
import { CHAINS } from '@/config/web3/chains'
import { LedgerIFrameConnector } from '@/connection/connectors/LedgerIFrameConnector/ledgerIFrameConnector'
import { setRecentConnectionMeta } from '@/connection/utils'
import { formatAccount } from '@/utils/formats'
import { swapUrlChain } from '@/utils/web3'

const WalletConnectionMenu = () => {
  const { isOpen, toggleMenu } = useTopMenuState()
  const { account, connector, chainId } = useWeb3React()
  const { chain, setChain } = useConnectionState()
  const [menuOpen, setMenuOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<EventTarget & HTMLDivElement>()
  const navigate = useNavigate()
  const t = useLocale()

  const dropdownRef = useRef(null)

  const { belowPhabletBreakpoint } = useBreakpointDetection()

  const { pathname } = useLocation()

  const isGnosisSafe = useMemo(() => {
    if (account) {
      return (
        connector instanceof GnosisSafe ||
        connector instanceof LedgerIFrameConnector
      )
    }

    return false
  }, [account])

  useEffect(() => {
    if (IS_PUBLIC_TESTNET) {
      setChain(SupportedNetworks.SEPOLIA)
      return
    }
    if (pathname.includes('arb')) {
      setChain(SupportedNetworks.ARBITRUM)
      return
    }
    setChain(SupportedNetworks.ETHEREUM)
  }, [pathname])

  const disconnect = useCallback(() => {
    if (connector && connector.deactivate) {
      connector.deactivate()
    }
    connector.resetState()
    setRecentConnectionMeta(undefined)
  }, [connector])

  const handleMenuOpen = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    const { currentTarget } = event

    setMenuOpen(true)
    setAnchorEl(currentTarget)
    return
  }

  const handleChainSwitch = async (key: number) => {
    swapUrlChain(pathname, key, navigate)

    setChain(key)

    if (chainId !== key && account) {
      connector.provider.request({
        method: 'wallet_switchEthereumChain',
        params: [
          {
            chainId: ethers.utils.hexValue(key),
          },
        ],
      })
    }
  }

  const handleDropdownClickOutside = (event: any) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setMenuOpen(false)
    }
  }

  useEffect(() => {
    if (menuOpen) {
      document.addEventListener('mousedown', handleDropdownClickOutside)
    }
  }, [menuOpen])

  return (
    <Box
      display='flex'
      justifyContent='center'
      alignItems='center'
      padding={(theme) => theme.spacing(2, 0)}
    >
      {!isGnosisSafe && (
        <>
          <Box
            display='flex'
            alignItems='center'
            mr={`${account ? '' : '1rem'}`}
            sx={{ cursor: 'pointer' }}
            onMouseOver={(event) => handleMenuOpen(event)}
          >
            <Box
              display='flex'
              alignItems='center'
              sx={{ transform: 'scale(1.5)' }}
              mr='0.4rem'
            >
              {mapIcons(chain)}
            </Box>
            <Box display='flex' alignItems='center'>
              <KeyboardArrowDownIcon
                fontSize='small'
                sx={{ color: 'text.secondary' }}
              />
            </Box>
          </Box>
          <Popper
            ref={dropdownRef}
            open={menuOpen}
            anchorEl={anchorEl}
            placement='bottom-end'
            onMouseLeave={() => setMenuOpen(false)}
            sx={{ zIndex: 1001 }}
            modifiers={[
              {
                name: 'offset',
                options: {
                  offset: [0, 8],
                },
              },
            ]}
          >
            <Paper
              elevation={4}
              sx={{
                borderRadius: (theme) => theme.spacing(1),
                overflow: 'hidden',
                py: '0.4rem',
              }}
            >
              <MenuList>
                {Object.keys(CHAINS)
                  .filter((key) => {
                    if (IS_PUBLIC_TESTNET) {
                      return key === SupportedNetworks.SEPOLIA.toString()
                    }

                    return key !== SupportedNetworks.SEPOLIA.toString()
                  })
                  .map((key) => {
                    const chainInfo = CHAINS[+key]

                    return (
                      <Box
                        onClick={() => handleChainSwitch(+key)}
                        key={key}
                        sx={{
                          cursor: 'pointer',
                          '&:hover': {
                            color: '#006BA6',
                            backgroundColor: '#fff',
                          },
                          width: '196px',
                          display: 'grid',
                          gridTemplateColumns: '1fr 3fr 1fr',
                          justifyContent: 'center',
                          alignItems: 'center',
                          paddingY: '0.4rem',
                        }}
                      >
                        <Box
                          display='flex'
                          alignItems='center'
                          sx={{ transform: 'scale(1.5)' }}
                          ml='1rem'
                        >
                          {mapIcons(key)}
                        </Box>
                        <Box>{chainInfo.name}</Box>
                        {chain === +key && (
                          <Box
                            display='flex'
                            alignItems='center'
                            sx={{ color: 'text.secondary' }}
                          >
                            <CheckIcon fontSize='small' />
                          </Box>
                        )}
                      </Box>
                    )
                  })}
              </MenuList>
            </Paper>
          </Popper>
        </>
      )}
      {!isOpen &&
        (account && connector ? (
          <Box display='flex' alignItems='center'>
            <Box
              display='flex'
              sx={{
                transform: 'scale(1.4)',
                px: belowPhabletBreakpoint ? 0.5 : 1,
              }}
            ></Box>
            {!belowPhabletBreakpoint && (
              <Box
                display='flex'
                alignItems='center'
                gap={1}
                sx={{
                  mx: 0.5,
                  border: 1,
                  borderColor: 'rgba(0, 0, 0, 0.23)',
                  borderStyle: 'solid',
                  borderRadius: 4,
                  px: 0.75,
                  py: 0.5,
                }}
              >
                <Avatar
                  sx={{
                    width: 24,
                    height: 24,
                  }}
                />
                <Typography variant='body2'>
                  {account
                    ? formatAccount(account, 10)
                    : t(
                        'components.organisms.sideMenu.walletConnectionMenu.loading.label'
                      )}
                </Typography>
              </Box>
            )}
            {belowPhabletBreakpoint && (
              <Avatar
                sx={{
                  width: 24,
                  height: 24,
                }}
              />
            )}
            {!belowPhabletBreakpoint && !isGnosisSafe && (
              <IconButton size='small' onClick={disconnect}>
                <LogoutIcon />
              </IconButton>
            )}
          </Box>
        ) : (
          <Button
            variant='contained'
            size='small'
            sx={{ justifySelf: 'center', whiteSpace: 'nowrap' }}
            onClick={() => toggleMenu(true)}
          >
            {t(
              'components.organisms.sideMenu.walletConnectionMenu.buttons.connect.label'
            )}
          </Button>
        ))}
    </Box>
  )
}

export default WalletConnectionMenu
