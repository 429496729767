import { Box, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import CountUp from 'react-countup'

import useConnectionState from '@/hooks/context/useConnectionState'
import useLocale from '@/hooks/context/useLocale'
import useSmartVaultsTableState from '@/hooks/context/useSmartVaultsTableState'
import useBreakpointDetection from '@/hooks/general/useBreakpointDetection'
import useSmartVaults from '@/hooks/smartVaults/useSmartVaults'
import useStrategies from '@/hooks/strategies/useStrategies'

import { MainContainer } from '@/components/atoms/MainContainer'

import VaultHeader from '../../assets/background/VaultHeader.png'

const VaultsOverviewHeader = () => {
  const t = useLocale()

  const { chain } = useConnectionState()

  const {
    belowTabletBreakpoint,
    belowPhabletBreakpoint,
    belowMobileBreakpoint,
  } = useBreakpointDetection()

  const {
    page,
    limit,
    assetGroupId,
    riskModelProviderId,
    smartVaultsTableSort,
    search,
  } = useSmartVaultsTableState()

  const { totalUnfilteredVaults } = useSmartVaults(
    page,
    limit,
    assetGroupId,
    riskModelProviderId,
    smartVaultsTableSort,
    search
  )
  const { totalStrategies } = useStrategies()

  const [unfilteredVaultsCount, setUnfilteredVaultsCount] = useState<number>(0)

  useEffect(() => {
    setUnfilteredVaultsCount(totalUnfilteredVaults)
  }, [totalUnfilteredVaults, chain])

  return (
    <>
      <Box
        width='100%'
        display='flex'
        justifyContent='center'
        paddingTop='1rem'
        paddingBottom='10rem'
        sx={{
          backgroundImage: `url(${VaultHeader})`,
          bgcolor: '#006BA60D',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '50% 20%',
        }}
        mb='1rem'
      >
        <MainContainer
          mb='1rem'
          display='flex'
          flexDirection='column'
          gap='0.5rem'
        >
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='start'
            marginTop='2rem'
            gap={`${belowPhabletBreakpoint ? '2rem' : ''}`}
            flexDirection={`${belowPhabletBreakpoint ? 'column' : 'row'}`}
          >
            <Box
              display='flex'
              justifyContent='space-between'
              alignItems='center'
              paddingLeft='0.3rem'
            >
              <Box
                display='flex'
                flexDirection='column'
                alignItems='start'
                gap='0.3rem'
              >
                <Typography variant='body1' fontSize='24px'>
                  {t('pages.smartVaults.vaultsOverview.title')}
                </Typography>
                <Box
                  display={`${belowTabletBreakpoint ? 'column' : 'flex'}`}
                  alignItems='center'
                  fontSize={belowMobileBreakpoint ? '14px' : '16px'}
                >
                  <Box display='flex' alignItems='center'>
                    <Typography variant='body1' fontSize='16px'>
                      {t('pages.smartVaults.vaultsOverview.subtitle1')}
                    </Typography>
                    &nbsp;
                    {unfilteredVaultsCount ? (
                      <CountUp
                        start={0}
                        end={unfilteredVaultsCount}
                        duration={1}
                      />
                    ) : (
                      '0'
                    )}
                    &nbsp;
                    <Typography variant='body1' fontSize='16px'>
                      {t('pages.smartVaults.vaultsOverview.subtitle2')}
                    </Typography>
                    &nbsp;
                  </Box>
                  <Box display='flex' alignItems='center'>
                    <Typography variant='body1' fontSize='16px'>
                      {t('pages.smartVaults.vaultsOverview.subtitle3')}
                    </Typography>
                    &nbsp;
                    {totalStrategies ? (
                      <CountUp start={0} end={+totalStrategies} duration={1} />
                    ) : (
                      '0'
                    )}
                    &nbsp;
                    <Typography variant='body1' fontSize='16px'>
                      {t('pages.smartVaults.vaultsOverview.subtitle4')}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              display='flex'
              flexDirection='column'
              alignItems={`${belowPhabletBreakpoint ? 'start' : 'end'}`}
              paddingLeft='0.3rem'
              gap='0.3rem'
            >
              <Box display='flex' alignItems='center'>
                {/*<Box
                  component={Link}
                  color='primary'
                  to='https://docs.spool.fi/glossary/spool-glossary#total-value-routed-tvr'
                  target='_blank'
                  display='flex'
                  sx={{
                    '&:hover': {
                      color: 'primary',
                    },
                  }}
                >
                  &nbsp;
                  <Typography
                    fontSize='16px'
                    color='primary'
                    sx={{ '&:hover': { color: '#004D84' } }}
                  >
                    Learn More
                  </Typography>
                </Box>*/}
              </Box>
            </Box>
          </Box>
        </MainContainer>
      </Box>
    </>
  )
}

export default VaultsOverviewHeader
